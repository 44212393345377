import React from 'react';
import axios from 'axios';
import ReactWordcloud from 'react-wordcloud';
import { Resizable } from 're-resizable';

// import logo from './logo.svg';
import './App.css';
// import words from './words';
import allTemplates from './templates';

const resizeStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

export default class App extends React.Component {
    constructor (props) {
        super(props);

        this.updateTemplate = this.updateTemplate.bind(this);
        this.displayTemplate = this.displayTemplate.bind(this);
        this.templateUpdated = this.templateUpdated.bind(this);

        this.refreshJobId = null;
        this.state = {
            template: null,
            displayWords: [],
            pause: false
        };

        this.skip = 1;
        this.lastDispWords = [];
    }

    componentDidMount() {
        // run once
        const template = this.updateTemplate();
        const refreshRate = template.refreshRate && template.refreshRate > 1000 ? template.refreshRate : 15000;

        this.displayTemplate(template);
        this.refreshJobId = setInterval(()=> {
            this.displayTemplate(template);
        }, refreshRate);

        window.addEventListener("hashchange", e => this.templateUpdated());
        document.addEventListener('keyup', event => {
            const key = event.key || event.keyCode;
            if(key === ' ' || key === 128) {
                this.setState({pause: !this.state.pause});
            }
        });
    }

    componentWillUnmount() {
        if(this.refreshJobId) {
            clearInterval(this.refreshJobId);
        }
    }

    getUrlVars() {
        var vars = {};
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
            vars[key] = value;
        });
        return vars;
    }

    updateTemplate() {
        const params = this.getUrlVars();

        const templateName = params['template'];
        const max = params['max'] || 100;

        let template = allTemplates[templateName];
        template = template ? template : allTemplates['default'];
        template.maxWords = max;

        console.log('request received:', params);
        console.log('requested template name:', templateName);
        console.log('using template', template);

        return template;
    }

    displayTemplate(template) {
        if(this.state.pause !== true) {
            axios.get(template.url).then(rst => {

                let currWords = rst.data.map(({id, subscriber, subscriberName}) => ({
                    text: subscriber,
                    value: parseInt(subscriberName) || 0
                }));

                currWords.sort((a, b) => b.value - a.value);

                // enable this if you need dummy
                // let i = 0;
                // while(currWords.length < template.maxWords) {
                //
                //     currWords.push(words[i]);
                //     i ++;
                // }

                // console.log('slicing', currWords.slice(0, template.maxWords-1));

                this.setState({displayWords: currWords.slice(0, template.maxWords), template});

            });
        }
    }

    templateUpdated() {
        const template = this.updateTemplate();
        if(template !== this.state.template) {

            if(this.refreshJobId) {
                clearInterval(this.refreshJobId);
            }

            const refreshRate = template.refreshRate && template.refreshRate > 1000 ? template.refreshRate : 15000;

            this.displayTemplate(template);
            this.refreshJobId = setInterval(()=> {
                this.displayTemplate(template);
            }, refreshRate);
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(nextState.pause === true) {
            console.log('paused');
            return false;
        }
        return true;
    }

    render() {
        if(!this.state.template){
            return (<div></div>);
        }

        const dispWords = this.state.pause === true ? this.lastDispWords : this.state.displayWords.map(w => ({...w}));
        this.lastDispWords = dispWords;
        const bgColor = this.state.template.backgroundColor ? this.state.template.backgroundColor : 'rgb(240, 240, 240)';

        document.body.style.background = bgColor;

        return (
            <div style={{position: 'fixed', top: 0, left: 0, bottom: 0, right: 0, height: '100vh', width: '100vw', background:bgColor, overflow: 'hidden'}}>
                <Resizable
                    defaultSize={{
                        width: '100vw',
                        height: '100vh',
                    }}>

                    {this.state.template &&
                        <ReactWordcloud words={dispWords} options={this.state.template.option} maxWords={this.state.template.maxWords}/>
                    }

                </Resizable>
            </div>
        );
    }
}


