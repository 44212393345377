// the 'option' object passed to the react-wordcloud lib may have the following fields
// option: {
//     colors: String[],
//     deterministic: Boolean,
//     enableTooltip: Boolean,
//     fontFamily: String,
//     fontSizes: [number, number],
//     fontStyle: String,
//     fontWeight: String,
//     padding: Number,
//     rotationAngles: [number, number],
//     rotations: Number,
//     scale: ['linear', 'log', 'sqrt'],
//     spiral: ['archimedean', 'rectangular'],
//     transitionDuration: Number,
// }

export default {
	'default': {
		name: 'default template',
		option : {
			fontFamily: 'Akkurat, Arial, serif',
			fontWeight: 'bold',
			fontStyle: 'normal',
			fontSizes: [40, 250],
			colors: ['#001E82', '#0064D2', '#0099F8', '#35237F', '#5E50BF', '#8A92C5'],
			enableTooltip: false,
			deterministic: false,
			padding: 1,
			rotations: 1,
			rotationAngles: [0],
			scale: 'linear',
			spiral: 'rectangular',
			transitionDuration: 2000,
		},
		refreshRate: 15000,
		url: 'https://admin.cosi.rawideas.com/index.php/cosi/datafeed/WordCloud%20%231_ff0d0b172ebc2906a04b3ee8be6b2492/asc/250',
	},
	'telstra': {
		name: 'telstra',
		option : {
			fontFamily: 'Akkurat, Arial, serif',
			fontWeight: 'bold',
			fontStyle: 'normal',
			fontSizes: [40, 250],
			colors: ['#001E82', '#0064D2', '#0099F8', '#35237F', '#5E50BF', '#8A92C5'],
			rotations: 10,
			rotationAngles: [-60, 60],
			scale: 'linear',
			padding: 2,
			spiral: 'rectangular',
			transitionDuration: 2000,
		},
		refreshRate: 15000,
		backgroundColor: '#000000',
		url: 'https://admin.cosi.rawideas.com/index.php/cosi/datafeed/WordCloud%20%231_ff0d0b172ebc2906a04b3ee8be6b2492/asc/250',
	},
	'xmas': {
		name: 'xmas',
		option: {
			fontFamily: 'Arial, serif',
			fontWeight: 'bold',
			fontStyle: 'normal',
			fontSizes: [40, 250],
			colors: ['green', 'red', 'green', 'gold', 'green'],
			enableTooltip: false,
			deterministic: false,
			padding: 1,
			rotations: 1,
			rotationAngles: [0],
			scale: 'linear',
			spiral: 'rectangular',
			transitionDuration: 2000,
		},
		backgroundColor: '#333333',
		refreshRate: 60000,
		url: 'https://admin.cosi.rawideas.com/index.php/cosi/datafeed/Raw%20Ideas%20Xmas%20List_308745b9af84bd32199420121bb728ef/asc/250',
	},
	'schoolspec': {
		name: 'schoolspec',
		option : {
			fontFamily: 'Source Sans Pro, sans-serif',
			fontWeight: 'bold',
			fontStyle: 'normal',
			fontSizes: [40, 250],
			colors: ['#e5007a', '#e74011', '#fab70a', '#56af31', '#1951a0', '#831f82'],
			enableTooltip: false,
			deterministic: false,
			padding: 1,
			rotations: 1,
			rotationAngles: [0],
			scale: 'linear',
			spiral: 'rectangular',
			transitionDuration: 2000,
		},
		backgroundColor: '#000000',
		refreshRate: 15000,
		url: 'https://admin.cosi.rawideas.com/index.php/cosi/datafeed/WordCloud%20%231_ff0d0b172ebc2906a04b3ee8be6b2492/asc/250',
	},
	// 'pacific': {
	// 	option : {
	// 		gridSize: 15,
	// 		fontFamily: 'Akkurat, Arial, serif',
	// 		fontWeight: 'bold',
	// 		fontStyle: 'normal',
	// 		fontSizes: [10, 20],
	// 		colors: ['#001E82', '#0064D2', '#0099F8', '#35237F', '#5E50BF', '#8A92C5'],
	// 		rotateRatio: 0,
	// 		backgroundColor: '#E6E6E6',
	// 		clearCanvas: true,
	// 		drawOutOfBound: false,
	// 		shuffle: true,
	// 	},
	// 	backgroundPic: './dist/backgrounds/ellipse.png',
	// 	// backgroundPic: './dist/backgrounds/logo.png',
	// 	refreshRate: 0,
	// 	url: 'https://admin.cosi.rawideas.com/index.php/cosi/datafeed/WordCloud%20%232_24db741b597f43bb5f05345faf980834/asc/250',
	// 	// minimumWords: 300
	// },
	// 'twilight': {
	// 	option : {
	// 		gridSize: 15,
	// 		fontFamily: 'Akkurat, Arial, serif',
	// 		fontWeight: 'bold',
	// 		fontStyle: 'normal',
	// 		fontSizes: [10, 20],
	// 		colors: ['#9E043E', '#D93923', '#F05A00', '#D93923', '#F05A00', '#35237F', '#8A92C5', '#5E50BF', '#A0148C'],
	// 		// color: '#000000',
	// 		rotateRatio: 0,
	// 		backgroundColor: '#E6E6E6',
	// 		clearCanvas: true,
	// 		drawOutOfBound: false,
	// 		shuffle: true,
	// 	},
	// 	backgroundPic: './dist/backgrounds/ellipse.png',
	// 	// backgroundPic: './dist/backgrounds/logo.png',
	// 	refreshRate: 0,
	// 	url: 'https://admin.cosi.rawideas.com/index.php/cosi/datafeed/WordCloud%20%231_ff0d0b172ebc2906a04b3ee8be6b2492/asc/250',
	// 	// url: 'https://admin.cosi.rawideas.com/index.php/cosi/datafeed/Raw%20Cloud_763e119d086c4065517bc6f6f771b6b7/asc/250',
	// 	// minimumWords: 300
	// },
	// 'rawideas': {
	// 	option: {
	// 		gridSize: 5,
	// 		fontFamily: 'Arial, serif',
	// 		fontWeight: 'bold',
	// 		fontStyle: 'normal',
	// 		fontSizes: [10, 20],
	// 		backgroundColor: '#ededed',
	// 		colors: ['#059fdc', '#3d4f95', '#059fdc', '#993a95', '#059fdc', '#d63657', '#059fdc', '#f16f20', '#0093a9'],
	// 		rotateRatio: 0.75,
	// 		rotationSteps: 5,
	// 		minRotation: - Math.PI / 2,
	// 		maxRotation: Math.PI / 2,
	//
	// 		// shuffle: true,
	// 		// clearCanvas: true,
	// 		// drawOutOfBound: true,
	// 		// origin: null,
	//
	// 		// drawMask: false,
	// 		// maskColor: 'rgba(255,0,0,0.3)',
	// 		// maskGapWidth: 0.3,
	//
	// 		// wait: 0, // disabled
	// 		// abortThreshold: 0, // disabled
	// 		// abort: function noop() {},
	//
	// 		// shape: 'circle',
	// 		ellipticity: 0.75,
	// 		// width: 100,
	// 		// height: 100,
	//
	// 		// classes: null,
	//
	// 		// hover: null,
	// 		// click: null
	// 	},
	// 	backgroundPic: './dist/backgrounds/ri.png',
	// 	refreshRate: 0,
	// 	url: 'https://admin.cosi.rawideas.com/index.php/cosi/datafeed/Raw%20Cloud_763e119d086c4065517bc6f6f771b6b7/asc/250',
	// 	minimumWords: 1500
	// },
	// 'rawideasTest': {
	// 	option: {
	// 		gridSize: 5,
	// 		fontFamily: 'Arial, serif',
	// 		fontWeight: 'bold',
	// 		fontStyle: 'normal',
	// 		backgroundColor: '#E6E6E6',
	// 		colors: ['#3d4f95', '#993a95', '#059fdc', '#d63657', '#f16f20', '#0093a9'],
	// 		rotateRatio: 0.75,
	// 		rotationSteps: 5,
	// 		minRotation: - Math.PI / 2,
	// 		maxRotation: Math.PI / 2,
	//
	// 		// shape: 'circle',
	// 		ellipticity: 0.75,
	// 		// width: 100,
	// 		// height: 100,
	//
	// 	},
	// 	backgroundPic: './dist/backgrounds/heart.png',
	// 	refreshRate: 300,
	// 	url: 'https://admin.cosi.rawideas.com/index.php/cosi/datafeed/RawTest_b6bb003e9d2067d3ed3692bbbb54176b/asc/10',
	// 	minimumWords: 500
	// },

};